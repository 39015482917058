import * as React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { IconButton } from "@mui/material"
import LaunchIcon from "@mui/icons-material/Launch"
import EditIcon from "@mui/icons-material/Edit"

import * as API from "../../../util/apiClient"
import AccountAudienceDetails from "./AccountAudienceDetails"
import AccountCampaignsWrapper from "./AccountCampaignsWrapper"
import AccountInsightsDetails from "./AccountInsightsDetails"
import Lists from "../Lists"
import Notes from "../Notes"
import Search from "../Content/Search"
import Tabs, { TabLabelProps } from "../../Tabs"
import { Scope } from "../../../util/types"
import { setEditMode, setOpenModal } from "../../../state/profileSlice"
import { useSelector, useDispatch } from "../../../state/hooks"
import Communications from "../Communications"

import "./style.sass"

// eslint-disable-next-line no-shadow
enum TabPathsEnum {
  INSIGHTS = "insights",
  AUDIENCE = "audience",
  NOTES = "notes",
  LISTS = "lists",
  CAMPAIGNS = "campaigns",
  COMMS = "communications",
  CONTENT = "content",
}
interface ProfileTabLabelProps extends TabLabelProps {
  path: TabPathsEnum.INSIGHTS
  | TabPathsEnum.AUDIENCE
  | TabPathsEnum.NOTES
  | TabPathsEnum.LISTS
  | TabPathsEnum.CAMPAIGNS
  | TabPathsEnum.COMMS
  | TabPathsEnum.CONTENT
}

export default function ProfileTabs() {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ProfileTabs" })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { tabPath } = useParams()
  const profile = useSelector(({ profile: profileSlice }) => profileSlice.profile)
  const scopes = useSelector(({ user }) => user.scopes)
  const socialProfile = useSelector(({ socialProfile: socialProfileSlice }) => socialProfileSlice.profile)

  const tabs: ProfileTabLabelProps[] = React.useMemo(() => {
    const t = [
      { label: translate("Account Insights"), path: TabPathsEnum.INSIGHTS },
      { label: translate("Audience"), path: TabPathsEnum.AUDIENCE },
      { label: translate("Content"), path: TabPathsEnum.CONTENT },
    ]
    if (
      API.isSuccess(socialProfile)
      && socialProfile.payload.socialAccount.personality != null
    ) t.push({ label: translate("Notes"), path: TabPathsEnum.NOTES })
    if (scopes.includes(Scope.FEATURE_INFLUENCER_LIST)) t.push({ label: translate("Lists"), path: TabPathsEnum.LISTS })
    if (scopes.includes(Scope.FEATURE_CAMPAIGN)) t.push({ label: translate("Campaigns"), path: TabPathsEnum.CAMPAIGNS })
    if (scopes.includes(Scope.COMMUNICATIONS_VIEW)
      && scopes.includes(Scope.DEVELOPMENT)) t.push({ label: translate("Communications"), path: TabPathsEnum.COMMS })
    return t
  }, [ translate, scopes, socialProfile ])

  const setTab = (tabIndex: number) => {
    const newTabPath = tabs[tabIndex].path
    const pathPrefix = tabPath ? "../" : ""
    navigate(`${ pathPrefix }${ newTabPath }`, { relative: "path" })
  }

  const tabContent = () => {
    switch (tabPath) {
      case TabPathsEnum.INSIGHTS:
      case undefined:
        return null
      case TabPathsEnum.AUDIENCE:
        return null
      case TabPathsEnum.NOTES:
        return null
      case TabPathsEnum.CONTENT:
        return null
      case TabPathsEnum.LISTS:
        if (scopes.includes(Scope.FEATURE_INFLUENCER_LIST)) {
          return null
        }
        return <p>User does not have access to this tab!</p>
      case TabPathsEnum.CAMPAIGNS:
        if (scopes.includes(Scope.FEATURE_CAMPAIGN)) {
          return null
        }
        return <p>User does not have access to this tab!</p>
      case TabPathsEnum.COMMS:
        if (scopes.includes(Scope.COMMUNICATIONS_VIEW) && scopes.includes(Scope.DEVELOPMENT)) {
          return null
        }
        return <p>User does not have access to this tab!</p>
      default:
        return <p>Error or this tab doesnt exist yet</p>
    }
  }

  const tabPage = () => {
    switch (tabPath) {
      case TabPathsEnum.INSIGHTS:
      case undefined:
        return <AccountInsightsDetails />
      case TabPathsEnum.AUDIENCE:
        return <AccountAudienceDetails />
      case TabPathsEnum.NOTES:
        if (
          API.isSuccess(socialProfile)
          && socialProfile.payload.socialAccount.personality != null
        ) {
          return <Notes />
        }

        return null
      case TabPathsEnum.LISTS:
        if (scopes.includes(Scope.FEATURE_INFLUENCER_LIST)) {
          return <Lists />
        }
        return null
      case TabPathsEnum.CAMPAIGNS:
        if (scopes.includes(Scope.FEATURE_CAMPAIGN)) {
          return <AccountCampaignsWrapper />
        }
        return null
      case TabPathsEnum.COMMS:
        if (scopes.includes(Scope.COMMUNICATIONS_VIEW) && scopes.includes(Scope.DEVELOPMENT)) {
          return <Communications />
        }
        return null
      case TabPathsEnum.CONTENT:
        if (!API.isSuccess(socialProfile)) return null

        // When the social account doesn't have an associated profile, it's
        // safe to assume this search mount context should be the social
        // account content tab under the Search hierarchy.
        return (
          <Search
            mountContext={
              socialProfile
                .payload.socialAccount.personality == null
                ? "searchSocialAccount"
                : "profile"
            }
            contentContextMenu={ {
              showAddPostButton: false,
              showViewMatchingPostsButton: false,
              showViewProfileButton: false,
            } }
          />
        )
      default:
        return null
    }
  }

  const tabIndex = React.useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === tabPath)
    const index = foundTab ? tabs.indexOf(foundTab) : 0
    return index
  }, [ tabPath, socialProfile ])

  const platformURL = (socialProfile !== "loading" && socialProfile !== "init" && socialProfile.status !== "error")
  && socialProfile.payload.socialAccount.networkUrl

  const editProfileHandler = () => {
    dispatch(setEditMode(true))
    dispatch(setOpenModal(true))
  }
  return (
    <>
      <div className="cp_profile_component-tabs-container">
        <Tabs
          handleChange={ setTab }
          tabs={ tabs }
          controlledValue={ tabIndex }
          controls={ API.isSuccess(socialProfile)
            ? (
              <div className="cp_profile_component-tabs-controls">
                { /* eslint-disable-next-line jsx-a11y/control-has-associated-label */ }
                <a href={ platformURL || "" } target="_blank" rel="noreferrer">
                  <IconButton>
                    <LaunchIcon />
                  </IconButton>
                </a>
                { (scopes.includes(Scope.INFLUENCERS_UPDATE)
                && scopes.includes(Scope.INFLUENCERS_READ)
                && socialProfile.payload.socialAccount.personality != null) && (
                  <IconButton
                    onClick={ editProfileHandler }
                    disabled={ profile === "loading" || profile === "init" || profile.status === "error" }
                  >
                    <EditIcon />
                  </IconButton>
                ) }
              </div>
            ) : (
              // Necessary to keep tab styles consistent when profile is loading
              <div className="cp_profile_component-tabs-controls" />
            ) }
        />
        <div className="cp_profile_component-tabs-content">
          { tabContent() }
        </div>
      </div>
      { tabPage() }
    </>
  )
}
