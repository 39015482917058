import React, { JSX, useEffect } from "react"
import {
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import RefreshIcon from "@mui/icons-material/Refresh"
import { useSearchParams } from "react-router-dom"

import { ReactComponent as BetaIcon } from "../../asset/img/beta.svg"
import PromptAI from "./PromptAI"
import ProfileFilters from "./ProfileFilters"
import NetworkFilter from "./AINetworkFilter"
import { Network } from "../../graphql/search"
import { TYPOGRAPHY } from "../../style/mui-styles"
import {
  cloneSearchInput,
  initialProfileInput,
  initialContentInput,
  setSearchInputQueryParams,
  setContentInputQueryParams,
  setProfileInputQueryParams,
  initialAudienceInput,
  setAudienceInputQueryParams,
} from "../../state/searchAISlice/helper"
import "./style.sass"
import { useDispatch, useSelector } from "../../state/hooks"
import { setSearchInput, setSelectedLocations } from "../../state/searchAISlice"
import AudienceFilters from "./AudienceFilters"
import ContentFilters from "./ContentFilters"

const DISABLED_NETWORKS: Network[] = [ Network.Facebook ]

export default function AISearchFilter(): JSX.Element {
  const {
    t: translate,
  } = useTranslation([], { keyPrefix: "component.AISearchFilter" })

  const dispatch = useDispatch()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const {
    searchAIInput: searchInput,
  } = useSelector(({ searchAI }) => searchAI)

  const resetQuickFilters = () => {
    const newInput = cloneSearchInput(searchInput)
    newInput.socialNetworks = [ Network.Youtube, Network.Instagram, Network.Tiktok, Network.Snapchat ]
    newInput.prompts = []
    dispatch(setSearchInput(newInput))
    setSearchInputQueryParams(
      newInput,
      searchParams,
      setSearchParams,
    )
  }

  const resetAllFilters = () => {
    const newInput = cloneSearchInput(searchInput)
    newInput.contentParams = initialContentInput()
    newInput.profileParams = initialProfileInput()
    newInput.audienceParams = initialAudienceInput()
    dispatch(setSearchInput(newInput))
    dispatch(setSelectedLocations([]))
  }

  useEffect(() => {
    setProfileInputQueryParams(
      searchInput.profileParams,
      searchParams,
      setSearchParams,
    )
  }, [ searchInput.profileParams ])

  useEffect(() => {
    setSearchInputQueryParams(
      searchInput,
      searchParams,
      setSearchParams,
    )
  }, [ searchInput.socialNetworks, searchInput.prompts, searchInput.type ])

  useEffect(() => {
    setAudienceInputQueryParams(
      searchInput.audienceParams,
      searchParams,
      setSearchParams,
    )
  }, [ searchInput.audienceParams ])

  useEffect(() => {
    setContentInputQueryParams(
      searchInput.contentParams,
      searchParams,
      setSearchParams,
    )
  }, [ searchInput.contentParams ])

  return (
    <>
      <Stack
        id="cp_component_ai-search-filter"
        padding={ 2 }
        className="cp_component_ai-search-filter"
        gap={ 2 }
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={ 1 }>
          <Stack direction="row" alignItems="center" gap={ 1 }>
            <Typography fontSize="1rem" fontWeight={ TYPOGRAPHY.FONT_WEIGHT.BOLDER }>
              { translate("Quick Filters") }
            </Typography>
            <BetaIcon />
          </Stack>
          <IconButton
            id="cp_component_ai-search-filter-reset-quick-filters"
            className="cp_component_ai-search-filter-refresh"
            size="small"
            onClick={ resetQuickFilters }
          >
            <RefreshIcon />
          </IconButton>
        </Stack>
        <PromptAI />
        <NetworkFilter disabledNetworks={ DISABLED_NETWORKS } hideDisabled={ false } />
      </Stack>
      <Stack
        mt={ 2 }
        id="cp_component_ai-search-filter-all"
        padding={ 2 }
        className="cp_component_ai-search-filter"
        gap={ 2 }
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={ 1 }>
          <Typography fontSize="1rem" fontWeight={ TYPOGRAPHY.FONT_WEIGHT.BOLDER }>
            { translate("All Filters") }
          </Typography>
          <IconButton
            id="cp_component_ai-search-filter-reset-all-filters"
            className="cp_component_ai-search-filter-refresh"
            size="small"
            onClick={ resetAllFilters }
          >
            <RefreshIcon />
          </IconButton>
        </Stack>
        <ProfileFilters />
        <AudienceFilters />
        <ContentFilters />
      </Stack>
    </>
  )
}
