import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { CreateRoleMap, Status } from "../../util/types"
import { RootState } from "../store"

/**
 * RoleModalState: The global state of the modal
 */
export interface RoleModalState {
  open: boolean
  createdRoleStatus: Status<GraphQL.CreateRoleMutation | null>
  editedRoleStatus: Status<GraphQL.UpdateRoleMutation | null>
  scopeMap?: CreateRoleMap
  isEditing: boolean
  roleId?: string
  role: Status<GraphQL.GetRoleQuery>
}

/**
 * initialState: The initial global state of the modal
 */
const initialState: RoleModalState = {
  open: false,
  createdRoleStatus: "init",
  editedRoleStatus: "init",
  isEditing: false,
  scopeMap: undefined,
  roleId: undefined,
  role: "init",
}

/**
 * roleModalSlice: The mutation/reducers for the state values
 */
export const roleModalSlice = createSlice({
  name: "roleModalSlice",
  initialState,
  reducers: {
    openRoleModal: (state, action: PayloadAction<boolean>) => ({
      ...state,
      open: action.payload,
    }),
    setCreatedRoleStatus: (state, action: PayloadAction<Status<GraphQL.CreateRoleMutation | null>>) => ({
      ...state,
      createdRoleStatus: action.payload,
    }),
    setEditedRoleStatus: (state, action: PayloadAction<Status<GraphQL.UpdateRoleMutation | null>>) => ({
      ...state,
      editedRoleStatus: action.payload,
    }),
    setModalRoleEditing: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isEditing: action.payload,
    }),
    setEditRoleId: (state, action: PayloadAction<string>) => ({
      ...state,
      roleId: action.payload,
    }),
    setScopeMap: (state, action: PayloadAction<CreateRoleMap>) => ({
      ...state,
      scopeMap: action.payload,
    }),
    setRole: (state, action: PayloadAction<Status<GraphQL.GetRoleQuery>>) => ({
      ...state,
      role: action.payload,
    }),
    resetRoleModalState: () => initialState,
  },
})

/**
 * Exporting the reducer functions for use withing components
 */
export const {
  openRoleModal,
  setCreatedRoleStatus,
  setEditRoleId,
  setEditedRoleStatus,
  setModalRoleEditing,
  setScopeMap,
  resetRoleModalState,
  setRole,
} = roleModalSlice.actions
export default roleModalSlice.reducer

/**
 * fetchRole: Grabs the role information including its scopes
 * @param id The id of the role to fetch
 * @returns void
 */
export const fetchRole = (id: string) => async (dispatch: Dispatch): Promise<void> => {
  // Inidicate we are fetching the role
  dispatch(setRole("loading"))

  // Fetch the role
  const role = await API.getRole({ id })

  // Set response
  dispatch(setRole(role))
}

/**
 * This creates a new role in the database
 * @param params All neccessary date to create role and respond back to caller
 * @returns void
 */
export const createRole = (params: {
  code: string,
  display: string,
  onSuccess: () => void,
  onError: () => void,
}) => async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
  // Set status to loading for creating role
  dispatch(setCreatedRoleStatus("loading"))

  // Bring in current state
  const { roleModal } = await getState()
  const { scopeMap: map } = roleModal

  if (map) {
    // Create the data
    const createRoleInput: GraphQL.CreateRoleMutationVariables = {
      code: params.code,
      display: params.display,
      scopeIds: Object.keys(map).flatMap((key) => map[key].filter((item) => item.enabled).map((item) => item.id.toString())),
    }

    // Create the role
    const role = await API.createRole(createRoleInput)

    // Respond to client for success/failure
    if (API.isSuccess(role)) params.onSuccess()
    if (API.isError(role)) params.onError()

    // Set the status
    dispatch(setCreatedRoleStatus(role))
  }
}

/**
 * updateRole: Updates the role in the database with any changed scopes
 * @param params The id, code, display and scopeIds
 * @returns void
 */
export const updateRole = (params: {
  id: string,
  code: string,
  display: string,
  onSuccess: () => void,
  onError: () => void,
}) => async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
  // Set status to loading
  dispatch(setEditedRoleStatus("loading"))

  // Bring in current state and get map
  const { roleModal } = await getState()
  const { scopeMap: map } = roleModal

  // Check the map
  if (map) {
    // Create the update data
    const updateRoleInput: GraphQL.UpdateRoleMutationVariables = {
      id: params.id,
      code: params.code,
      display: params.display,
      scopeIds: Object.keys(map).flatMap((key) => map[key].filter((item) => item.enabled).map((item) => item.id.toString())),
    }

    // Update the role
    const role = await API.updateRole(updateRoleInput)

    // Respond to client for success/failure
    if (API.isSuccess(role)) params.onSuccess()
    if (API.isError(role)) params.onError()

    // Set the status
    dispatch(setEditedRoleStatus(role))
  }
}
