import { createSlice } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { PayloadAction } from "@reduxjs/toolkit"

import * as GraphQL from "../../graphql"
import * as API from "../../util/apiClient"
import { RootState } from "../store"
import {
  AUDIENCE_DISPLAY_TOGGLES,
  CONTENT_DISPLAY_TOGGLES,
  INSIGHTS_DISPLAY_TOGGLES,
} from "../../util/constant"
import {
  ListTogglesType,
  Status,
  defaultListToggles,
} from "../../util/types"

// List Group Slice Interface and Initial State
export interface PublicListState {
  list: Status<GraphQL.GetPublicListByCodeQuery>
  listNameForInfoModal: Status<GraphQL.GetPublicListNameByCodeQuery>
  // TODO: Change selectedListSocialAccount to just be selectedSocialAccountId, then fill data with loadedListSocialAccount
  selectedListSocialAccount: GraphQL.SuggestionListSocialAccountForPublicListFragment | null | undefined
  loadedListSocialAccount: Status<GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQuery>
  audience: Status<GraphQL.GetPublicAudienceDemographicsQuery>
  userVerification: Status<GraphQL.PublicVerifySuggestionListAccessCodeByListCodeQuery>
  leftPanelStatus: "closed" | "default" | "expanded"
  rightPanelStatus: "closed" | "approve" | "feedback"
  infoRequiredModalOpen: boolean
  accessCodeCookieKey: string
  nameCookieKey: string
  companyCookieKey: string
  dailyEngagementRate: Status<GraphQL.FetchPublicHourlyEngagementSummaryQuery>
  mentionedBrands: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>
  workedWithBrands: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>
  detectedBrands: Status<GraphQL.PublicGetMentionedSocialAccountsQuery>
  mentionedPostsStatus: Status<GraphQL.GetPublicMentionedPostsQuery>
  mentionedPostsContent: Array<GraphQL.MentionedPostFragment>
  toggles: ListTogglesType
  topPosts: Status<GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQuery>
  postsByKeyword: Status<GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery>
  postsByImageId: Status<GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery>
  activeKeywordId: string | null | undefined
  activeImageId: string | null | undefined
  initialPostKeywords: Status<GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery>
  initialPostsImageTags: Status<GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery>
  postKeywordTags: GraphQL.SuggestionListKeyword[]
  postImageTags: GraphQL.SuggestionListImageTag[]
  infoRequiredSubmitCallback: () => any
}

const initialState: PublicListState = {
  list: "init",
  listNameForInfoModal: "init",
  selectedListSocialAccount: undefined,
  loadedListSocialAccount: "init",
  audience: "init",
  userVerification: "init",
  leftPanelStatus: "default",
  rightPanelStatus: "closed",
  infoRequiredModalOpen: false,
  accessCodeCookieKey: "",
  nameCookieKey: "",
  companyCookieKey: "",
  dailyEngagementRate: "init",
  mentionedBrands: "init",
  workedWithBrands: "init",
  detectedBrands: "init",
  mentionedPostsStatus: "init",
  mentionedPostsContent: [],
  toggles: defaultListToggles,
  topPosts: "init",
  postsByKeyword: "init",
  postsByImageId: "init",
  activeKeywordId: null,
  activeImageId: null,
  initialPostKeywords: "init",
  initialPostsImageTags: "init",
  postImageTags: [],
  postKeywordTags: [],
  infoRequiredSubmitCallback: () => {},
}

// List Group Slice
export const ListSlice = createSlice({
  name: "PublicList",
  initialState,
  reducers: {
    setList: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicListByCodeQuery>>,
    ) => ({
      ...state,
      list: action.payload,
    }),
    setListNameForInfoModal: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicListNameByCodeQuery>>,
    ) => ({
      ...state,
      listNameForInfoModal: action.payload,
    }),
    setSelectedListSocialAccount: (
      state,
      action: PayloadAction<GraphQL.SuggestionListSocialAccountForPublicListFragment | null>,
    ) => ({
      ...state,
      selectedListSocialAccount: action.payload,
    }),
    setLoadedListSocialAccount: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQuery>>,
    ) => ({
      ...state,
      loadedListSocialAccount: action.payload,
    }),
    setAudience: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicAudienceDemographicsQuery>>,
    ) => ({
      ...state,
      audience: action.payload,
    }),
    setUserVerification: (
      state,
      action: PayloadAction<Status<GraphQL.PublicVerifySuggestionListAccessCodeByListCodeQuery>>,
    ) => ({
      ...state,
      userVerification: action.payload,
    }),
    setLeftPanelStatus: (
      state,
      action: PayloadAction<"closed" | "default" | "expanded">,
    ) => ({
      ...state,
      leftPanelStatus: action.payload,
    }),
    setRightPanelStatus: (
      state,
      action: PayloadAction<"closed" | "approve" | "feedback">,
    ) => ({
      ...state,
      rightPanelStatus: action.payload,
    }),
    setInfoRequiredModalOpen: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      infoRequiredModalOpen: action.payload,
    }),
    setAccessCodeCookieKey: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      accessCodeCookieKey: action.payload,
    }),
    setNameCookieKey: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      nameCookieKey: action.payload,
    }),
    setCompanyCookieKey: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      companyCookieKey: action.payload,
    }),
    setDailyEngagementRate: (
      state,
      action: PayloadAction<Status<GraphQL.FetchPublicHourlyEngagementSummaryQuery>>,
    ) => ({
      ...state,
      dailyEngagementRate: action.payload,
    }),
    setMentionedBrands: (
      state,
      action: PayloadAction<Status<GraphQL.PublicGetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      mentionedBrands: action.payload,
    }),
    setWorkedWithBrands: (
      state,
      action: PayloadAction<Status<GraphQL.PublicGetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      workedWithBrands: action.payload,
    }),
    setDetectedBrands: (
      state,
      action: PayloadAction<Status<GraphQL.PublicGetMentionedSocialAccountsQuery>>,
    ) => ({
      ...state,
      detectedBrands: action.payload,
    }),
    setMentionedPostsStatus: (
      state,
      action: PayloadAction<Status<GraphQL.GetPublicMentionedPostsQuery>>,
    ) => ({
      ...state,
      mentionedPostsStatus: action.payload,
    }),
    setMentionedPostsContent: (
      state,
      action: PayloadAction<Array<GraphQL.MentionedPostFragment>>,
    ) => ({
      ...state,
      mentionedPostsContent: action.payload,
    }),
    setToggles: (
      state,
      action: PayloadAction<ListTogglesType>,
    ) => ({
      ...state,
      toggles: action.payload,
    }),
    setTopPosts: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQuery>>,
    ) => ({
      ...state,
      topPosts: action.payload,
    }),
    setPostsByKeyword: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery>>,
    ) => ({
      ...state,
      postsByKeyword: action.payload,
    }),
    setPostsByImageId: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery>>,
    ) => ({
      ...state,
      postsByImageId: action.payload,
    }),
    setActiveImageId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      activeImageId: action.payload,
    }),
    setActiveKeywordId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => ({
      ...state,
      activeKeywordId: action.payload,
    }),
    setInitialPostsKeywords: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQuery>>,
    ) => ({
      ...state,
      initialPostKeywords: action.payload,
    }),
    setInitialPostsImageTags: (
      state,
      action: PayloadAction<Status<GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQuery>>,
    ) => ({
      ...state,
      initialPostsImageTags: action.payload,
    }),
    setPostImageTags: (
      state,
      action: PayloadAction<GraphQL.SuggestionListImageTag[]>,
    ) => ({
      ...state,
      postImageTags: action.payload,
    }),
    setPostKeywordTags: (
      state,
      action: PayloadAction<GraphQL.SuggestionListKeyword[]>,
    ) => ({
      ...state,
      postKeywordTags: action.payload,
    }),
    setInfoRequiredSubmitCallback: (
      state,
      action: PayloadAction<() => any>,
    ) => ({
      ...state,
      infoRequiredSubmitCallback: action.payload,
    }),
  },
})

export const {
  setList,
  setListNameForInfoModal,
  setSelectedListSocialAccount,
  setLoadedListSocialAccount,
  setUserVerification,
  setLeftPanelStatus,
  setRightPanelStatus,
  setInfoRequiredModalOpen,
  setAccessCodeCookieKey,
  setCompanyCookieKey,
  setNameCookieKey,
  setDetectedBrands,
  setMentionedBrands,
  setMentionedPostsContent,
  setMentionedPostsStatus,
  setWorkedWithBrands,
  setDailyEngagementRate,
  setAudience,
  setToggles,
  setTopPosts,
  setPostsByKeyword,
  setPostsByImageId,
  setActiveImageId,
  setActiveKeywordId,
  setInitialPostsImageTags,
  setInitialPostsKeywords,
  setPostImageTags,
  setPostKeywordTags,
  setInfoRequiredSubmitCallback,
} = ListSlice.actions
export default ListSlice.reducer

// List Group Slice Thunks
export const fetchPublicList = (
  code: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setList("loading"))
  const listResult = await API.fetchPublicListByCode({ code })
  if (API.isSuccess(listResult)) {
    const listCategories = listResult.payload.publicSuggestionListByCode.suggestionListCategories

    // Sometimes suggestionListCategories have no social accounts.
    // Iterate to find first list social account to set to default
    let firstSocialAccount
    let i = 0
    let j = 0
    while (i < listCategories.length) {
      const listCategory = listCategories[i]
      while (j < listCategory.suggestionListSocialAccounts.length) {
        const socialAccount = listCategory.suggestionListSocialAccounts[j]
        if (socialAccount) {
          firstSocialAccount = socialAccount
          j = listCategory.suggestionListSocialAccounts.length
          i = listCategories.length
        }
        j += 1
      }
      i += 1
    }
    if (firstSocialAccount) {
      dispatch(setSelectedListSocialAccount(firstSocialAccount))
    } else {
      dispatch(setSelectedListSocialAccount(null))
    }
  }
  dispatch(setList(listResult))
}

export const fetchPublicListSocialProfile = (
  params: GraphQL.PublicSuggestionListSocialAccountByListCodeSocialAccountIdForListTabsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setLoadedListSocialAccount("loading"))
  const result = await API.fetchPublicListSocialAccount(params)
  dispatch(setLoadedListSocialAccount(result))
}

export const fetchPublicListName = (
  code: string,
) => async (
  dispatch: Dispatch,
): Promise<void> => {
  dispatch(setListNameForInfoModal("loading"))
  const listName = await API.fetchPublicListNameByCode({ code })
  dispatch(setListNameForInfoModal(listName))
}
export const fetchAudience = (
  params: GraphQL.GetPublicAudienceDemographicsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setAudience("loading"))

  const result = await API.fetchPublicListAudienceByCode(params)
  dispatch(setAudience(result))
}
export const approvePublicList = (
  params: {
    suggestionListCode: string,
    suggestionListSocialAccountId: string,
    onSuccess: () => any
  },
) => async () => {
  const result = await API.publicApproveSuggestionListSocialAccount({
    suggestionListCode: params.suggestionListCode,
    suggestionListSocialAccountId: params.suggestionListSocialAccountId,
  })
  if (result.status === "success") {
    params.onSuccess()
  }
}

export const createFeedback = (
  suggestionListCode: string,
  suggestionListSocialAccountId: string,
  message: string,
  onSuccess: () => void,
) => async () => {
  const result = await API.publicCreateSuggestionListSocialAccountFeedback({
    suggestionListCode, suggestionListSocialAccountId, message,
  })
  if (result.status === "success") {
    onSuccess()
  }
}

export const verifyPublicListByCode = (
  publicListCode: string,
  accessCode: string,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setUserVerification("loading"))
  const result = await API.verifyPublicListAccessCodeByListCode({
    code: publicListCode,
    accessCode,
  })
  dispatch(setUserVerification(result))

  return result
}

export const submitVerification = (
  publicListCode: string,
  accessCode: string,
  codeRestriction: GraphQL.SuggestionAccessCodeRestriction | null | undefined,
) => async (
  dispatch: Dispatch,
) => {
  if ((codeRestriction !== null || undefined)) {
    const result = await API.verifyPublicListAccessCodeByListCode({
      code: publicListCode,
      accessCode,
    })
    if (API.isError(result)) {
      dispatch(setUserVerification(result))
      return false
    }
    if (API.isSuccess(result)) {
      dispatch(setUserVerification(result))
      return !result.payload.publicVerifySuggestionListAccessCodeByListCode.isValid
    }
  }
  return false
}

export const getDailyHourlyEngagementRate = (
  variables: GraphQL.FetchPublicHourlyEngagementSummaryQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDailyEngagementRate("loading"))

  const result = await API.getPublicDailyHourlyEngagementSummary(variables)

  dispatch(setDailyEngagementRate(result))
}

export const fetchMentionedBrands = (
  params: Omit<GraphQL.PublicGetMentionedSocialAccountsQueryVariables, "mentionType">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setMentionedBrands("loading"))

  const result = await API.getPublicMentionedSocialAccounts({
    ...params,
    mentionType: GraphQL.MentionType.Brand,
  })

  dispatch(setMentionedBrands(result))
}

export const fetchWorkedWithBrands = (
  params: Omit<GraphQL.PublicGetMentionedSocialAccountsQueryVariables, "mentionType">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setWorkedWithBrands("loading"))

  const result = await API.getPublicMentionedSocialAccounts({
    ...params,
    mentionType: GraphQL.MentionType.BrandWorkedWith,
  })

  dispatch(setWorkedWithBrands(result))
}

export const fetchDetectedBrands = (
  params: Omit<GraphQL.PublicGetMentionedSocialAccountsQueryVariables, "mentionType">,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setDetectedBrands("loading"))

  const result = await API.getPublicMentionedSocialAccounts({
    ...params,
    mentionType: GraphQL.MentionType.BrandLogoDetection,
  })

  dispatch(setDetectedBrands(result))
}

export const getMentionedPosts = (
  params: GraphQL.GetPublicMentionedPostsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setMentionedPostsStatus("loading"))
  const result = await API.fetchPublicMentionedPosts({
    ...params,
  })

  if (API.isSuccess(result)) {
    dispatch(setMentionedPostsStatus(result))
    dispatch(setMentionedPostsContent(result.payload.publicGetMentionedCippus.rows))
  } else {
    dispatch(setMentionedPostsStatus({ status: "error", message: "There was an issue with the API request" }))
  }
}

export const getMoreMentionedPosts = (
  params: GraphQL.GetPublicMentionedPostsQueryVariables,
) => async (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const result = await API.fetchPublicMentionedPosts(params)

  if (API.isSuccess(result)) {
    const { listSocialProfile: { mentionedPostsContent: currentContent } } = getState()
    dispatch(setMentionedPostsContent([ ...currentContent, ...result.payload.publicGetMentionedCippus.rows ]))
  } else {
    dispatch(setMentionedPostsStatus({ status: "error", message: "There was an issue with the API request" }))
  }
}

export const loadToggles = (
  toggles: GraphQL.ToggleFragment[],
) => (
  dispatch: Dispatch,
) => {
  dispatch(setToggles({
    displayInsightsEngagementRateOverTime: toggles
      .some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_OVER_TIME),
    displayInsightsEngagementsByPostType: toggles
      .some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE),
    displayInsightsEngagementsByPostTypeReel: toggles
      .some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENT_RATE_BY_POST_TYPE_REEL),
    displayInsightsEngagementsHeatMap: toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.ENGAGEMENTS_HEAT_MAP),
    displayInsightsRecentMediaAIImageAnalysis: toggles
      .some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_AI_IMAGE_ANALYSIS),
    displayInsightsRecentMediaStories: toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.RECENT_MEDIA_STORIES),
    displayInsightsBrandsMentionedBrandsWorkedWith: toggles
      .some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.BRANDS_MENTIONED_BRANDS_WORKED_WITH),
    displayInsightsBrandsDetected: toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.BRANDS_DETECTED),
    displayInsightsPITraits: toggles.some(({ name }) => name === INSIGHTS_DISPLAY_TOGGLES.PI_TRAITS),
    displayAudienceBaseline: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.BASELINE),
    displayFollowersOverTime: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.FOLLOWERS_OVER_TIME),
    displayAudienceQuality: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.QUALITY),
    displayGender: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.GENDER),
    displayFamilyStatus: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.FAMILY_STATUS),
    displayAgeRange: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.AGE_RANGE),
    displayIncomeRange: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.INCOME_RANGE),
    displayEducationLevel: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.EDUCATION_LEVEL),
    displayEthnicities: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.ETHNICITIES),
    displayLanguages: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.LANGUAGES),
    displayReligions: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.RELIGIONS),
    displayCountries: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.COUNTRIES),
    displayStates: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.STATES),
    displayCities: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.CITIES),
    displayOccupations: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.OCCUPATIONS),
    displayIndustries: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.INDUSTRIES),
    displayEmployers: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.EMPLOYERS),
    displayUniversities: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.UNIVERSITIES),
    displayAffinities: toggles.some(({ name }) => name === AUDIENCE_DISPLAY_TOGGLES.AFFINITIES),
    displayTopPosts: toggles.some(({ name }) => name === CONTENT_DISPLAY_TOGGLES.TOP_POSTS),
    displayKeywords: toggles.some(({ name }) => name === CONTENT_DISPLAY_TOGGLES.KEYWORDS),
  }))
}

export const getTopPosts = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeForContentTabQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setTopPosts("loading"))
  const result = await API.fetchPublicListGroupByCodeForContentTab(params)
  dispatch(setTopPosts(result))
}

export const getInitialPostKeywords = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setInitialPostsKeywords("loading"))
  const result = await API.fetchPublicListSocialAccountForPostsByKeyword(params)
  dispatch(setInitialPostsKeywords(result))
}

export const getInitialPostImageTags = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setInitialPostsImageTags("loading"))
  const result = await API.fetchPublicListSocialAccountForPostsByImageId(params)
  dispatch(setInitialPostsImageTags(result))
}

export const getPostsByKeyword = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeForPostsQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setPostsByKeyword("loading"))
  const result = await API.fetchPublicListSocialAccountForPostsByKeyword(params)
  dispatch(setPostsByKeyword(result))
}

export const getPostsByImageId = (
  params: GraphQL.PublicSuggestionListSocialAccountByCodeWithImageIdQueryVariables,
) => async (
  dispatch: Dispatch,
) => {
  dispatch(setPostsByImageId("loading"))
  const result = await API.fetchPublicListSocialAccountForPostsByImageId(params)
  dispatch(setPostsByImageId(result))
}

export const initializeAccess = (listCode: string, accessCode: string) => async (
  dispatch: Dispatch,
) => {
  const result = await verifyPublicListByCode(listCode, accessCode)(dispatch)
  if (API.isSuccess(result)) {
    const {
      suggestionAccessCodeRestriction,
    } = result.payload.publicVerifySuggestionListAccessCodeByListCode
    submitVerification(listCode, accessCode, suggestionAccessCodeRestriction)(dispatch)
  }
}

// Non-thunk helper functions
export const userCanViewList = (
  accessCodeRestriction: GraphQL.SuggestionAccessCodeRestriction | undefined | null,
  userIsValid: boolean | null | undefined,
) => {
  if (userIsValid) {
    return true
  }
  if (!accessCodeRestriction || accessCodeRestriction === GraphQL.SuggestionAccessCodeRestriction.RequiredToView) {
    return false
  }
  if (accessCodeRestriction === GraphQL.SuggestionAccessCodeRestriction.RequiredToEdit) {
    return true
  }

  return false
}

export const userCanEdit = (
  accessCodeRestriction: GraphQL.SuggestionAccessCodeRestriction | undefined | null,
  userIsValid: boolean | null | undefined,
) => {
  if (userIsValid) {
    return true
  }
  if (accessCodeRestriction === null || accessCodeRestriction === GraphQL.SuggestionAccessCodeRestriction.RequiredToEdit) {
    return false
  }
  return false
}

export const checkDisplayAudienceTab = (toggles: ListTogglesType) => {
  const values = [
    toggles.displayAudienceBaseline,
    toggles.displayFollowersOverTime,
    toggles.displayAudienceQuality,
    toggles.displayGender,
    toggles.displayFamilyStatus,
    toggles.displayAgeRange,
    toggles.displayIncomeRange,
    toggles.displayEducationLevel,
    toggles.displayEthnicities,
    toggles.displayLanguages,
    toggles.displayReligions,
    toggles.displayCountries,
    toggles.displayStates,
    toggles.displayCities,
    toggles.displayOccupations,
    toggles.displayIndustries,
    toggles.displayEmployers,
    toggles.displayUniversities,
    toggles.displayAffinities,
  ]
  return values.includes(true)
}

export const checkDisplayAccountDetails = (toggles: ListTogglesType) => {
  const values = [
    toggles.displayInsightsEngagementRateOverTime,
    toggles.displayInsightsEngagementsByPostType,
    toggles.displayInsightsEngagementsByPostTypeReel,
    toggles.displayInsightsEngagementsHeatMap,
    toggles.displayInsightsRecentMediaAIImageAnalysis,
    toggles.displayInsightsBrandsMentionedBrandsWorkedWith,
    toggles.displayInsightsBrandsDetected,
    toggles.displayInsightsPITraits,
  ]
  return values.includes(true)
}
