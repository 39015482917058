import React from "react"
import { useTranslation } from "react-i18next"
import "./communications-sidebar.sass"
import {
  DraftsOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  GroupsOutlined,
  SendOutlined,
} from "@mui/icons-material"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "../../state/hooks"
import { Scope } from "../../util/types"
import Button from "../Button"
import Divider from "../Divider"
import { CommsFolder, COMM_VIEWS } from "../../util/constant"
import { setGroupAccountReloadStatus } from "../../state/communicationsPage"
import { setModalCreateMessageOpen } from "../../state/modalCreateMessageSlice"
import { resetListViewState, setConversationQuery } from "../../state/commEmailListView"

type Props = {
  to: CommsFolder,
}

export default function CommunicationsSidebar({
  to,
}: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.CommunicationsSidebar" })
  const { scopes } = useSelector(({ user: userSlice }) => userSlice)
  const navigate = useNavigate()
  const { vanity, commGroupID } = useParams()
  const dispatch = useDispatch()

  const handleCreateClick = () => {
    dispatch(setModalCreateMessageOpen(true))
  }

  const isSameView = (folder: CommsFolder) => {
    const currentPath = window.location.pathname
    if (currentPath.includes(COMM_VIEWS[folder])) return true
    return false
  }

  const handleListItemedSelected = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, folder: CommsFolder) => {
    if (isSameView(folder)) return
    // Select the index
    switch (folder) {
      case "Inbox":
        dispatch(setConversationQuery(null))
        dispatch(resetListViewState())
        navigate(`/${ vanity }/communications/group/${ commGroupID }/conversations`)
        break
      case "Sent":
        dispatch(setConversationQuery(null))
        dispatch(resetListViewState())
        navigate(`/${ vanity }/communications/group/${ commGroupID }/sent`)
        break
      case "Batch":
        dispatch(setConversationQuery(null))
        dispatch(resetListViewState())
        navigate(`/${ vanity }/communications/group/${ commGroupID }/batch`)
        break
      case "Drafts":
        dispatch(setConversationQuery(null))
        dispatch(resetListViewState())
        navigate(`/${ vanity }/communications/group/${ commGroupID }/drafts`)
        break
      case "GroupAccounts":
        dispatch(setGroupAccountReloadStatus(true))
        navigate(`/${ vanity }/communications/group/${ commGroupID }/accounts`)
        break
      default:
        break
    }
  }

  return (
    <div className="cp_component_communications-sidebar">
      <Button
        className="create-message-button"
        label={ translate("Create Message") }
        isPrimary={ false }
        onClick={ handleCreateClick }
      />
      <nav aria-label="Main communication folders">
        <List>
          <ListItem disablePadding={ true }>
            <ListItemButton
              className="comm-list-item-button"
              selected={ to === "Inbox" }
              onClick={ (event) => handleListItemedSelected(event, "Inbox") }
            >
              <ListItemIcon>
                <FolderOpenOutlined className="comm-list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Inbox") } />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding={ true }>
            <ListItemButton
              className="comm-list-item-button"
              selected={ to === "Sent" }
              onClick={ (event) => handleListItemedSelected(event, "Sent") }
            >
              <ListItemIcon>
                <SendOutlined className="comm-list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Sent") } />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding={ true }>
            <ListItemButton
              className="comm-list-item-button"
              selected={ to === "Batch" }
              onClick={ (event) => handleListItemedSelected(event, "Batch") }
            >
              <ListItemIcon>
                <FolderOutlined className="comm-list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Batch") } />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding={ true }>
            <ListItemButton
              className="comm-list-item-button"
              selected={ to === "Drafts" }
              onClick={ (event) => handleListItemedSelected(event, "Drafts") }
            >
              <ListItemIcon>
                <DraftsOutlined className="comm-list-item-icon" />
              </ListItemIcon>
              <ListItemText primary={ translate("Drafts") } />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      <Divider />
      { scopes.includes(Scope.FEATURE_COMMUNICATIONS) && (
        <nav aria-label="Group Accounts folders">
          <List>
            <ListItem disablePadding={ true }>
              <ListItemButton
                className="comm-list-item-button"
                selected={ to === "GroupAccounts" }
                onClick={ (event) => handleListItemedSelected(event, "GroupAccounts") }
              >
                <ListItemIcon>
                  <GroupsOutlined className="comm-list-item-icon" />
                </ListItemIcon>
                <ListItemText primary={ translate("Group Accounts") } />
              </ListItemButton>
            </ListItem>
          </List>
        </nav>
      ) }

    </div>
  )
}
